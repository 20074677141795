import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import { slug } from "../gatsby/articles.create"

export function TagCloud({minimumSize = 0.5, maximumSize = 2, className}) {
  const { tags } = useStaticQuery(query)

  const sortedTags = tags.nodes.map(countArticles).filter(hasArticles).sort(alphabetical)
  const size = getProportionalSizeFunction(minimumSize, maximumSize, sortedTags)

  return <div className={className}>
    {sortedTags.map(t => <Tag uri={slug("tags", t.name)} size={size(t.numberOfArticles)}>{t.description}</Tag> )}
  </div>

  function countArticles(tag) {
    return {
      ...tag,
      numberOfArticles: (tag['site___article'] || []).length
    }
  }

  function hasArticles(tag) {
    return tag.numberOfArticles
  }

  function alphabetical(leftTag, rightTag) {
    return leftTag.description < rightTag.description ? -1 : leftTag.description > rightTag.description
  }

  function getProportionalSizeFunction(minimumSize, maximumSize, tags) {
    const numberOfArticles = tags.map(tag => tag.numberOfArticles)
    const minimum = Math.min(...numberOfArticles)
    const range = Math.max(...numberOfArticles) - minimum

    return s => minimumSize + (maximumSize - minimumSize) * (s - minimum) / range
  }
}

function Tag({uri, size, children}) {
  return <Link to={uri} className='inline-block align-middle px-5 py-1 rounded-full hover:bg-primary hover:text-page' style={{fontSize: `${size}em`}}>{children}</Link>
}

const query = graphql`{
  tags: allContentfulSiteArticleTag {
    nodes {
      __typename
      name
      description
      site___article {
        id
      }
    }
  }
}`