import React from "react"
import { Link } from "gatsby"
import { spaceComponents } from "../util/dom"
import { join } from "../styles/styles"

export function Pagination({pathPrefix, context}) {
  return <div className='text-center text-base my-12'>
    <PageLink to={context.previousPagePath} className='px-4'>&laquo; Previous</PageLink>
    <DirectPageLinks pathPrefix={pathPrefix} total={context.numberOfPages} currentPage={context.pageNumber} />
    <PageLink to={context.nextPagePath} className='px-4'>Next &raquo;</PageLink>
  </div>

}

function DirectPageLinks({pathPrefix, total, currentPage}) {
  return spaceComponents(Array(total).fill(undefined).map((p, num) => <DirectPageLink pageNumber={num} />))

  function DirectPageLink({ pageNumber }) {
    return <PageLink hideOnMobile
      to={pageNumber === currentPage ? undefined : slug(pageNumber)}
      className='w-8 hidden md:inline-block'
      disabledClassName='text-page bg-primary-100'>{pageNumber + 1}</PageLink>
  }

  function slug(pageNumber) {
    return pageNumber ? `${pathPrefix}/${pageNumber + 1}` : pathPrefix
  }
}

function PageLink({to, className, disabledClassName = 'cursor-not-allowed text-ink-gray', children}) {
  return (to && <Link to={to} className={join(className, 'h-auto p-1 hover:text-page hover:bg-primary rounded-full')}>{children}</Link>)
    || <span className={join(className, disabledClassName, 'h-auto p-1 rounded-full')}>{children}</span>
}
