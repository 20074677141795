import React from "react"
import { ArticleCard } from "./Article"
import { PageWidth, Section } from "./Containers"
import { TagCloud } from "./TagCloud"
import { PageHeading, SectionHeading } from "./Headings"
import { Pagination } from "./Pagination"

export function ArticlesPage({pathPrefix, pageContext, children}) {
  return <PageWidth>
    <Section>
      <TagCloud className='text-center py-10' />
    </Section>
    <Section>
      <PageHeading>All articles</PageHeading>
    </Section>
    <Section cols={{md: 2, xl: 3}}>
      <Articles>{children}</Articles>
    </Section>
    <Section>
      <Pagination pathPrefix={pathPrefix} context={pageContext} />
    </Section>
  </PageWidth>
}

export function RecentArticles({ children }) {
  return <div>
    <SectionHeading className='mb-4'>Check out our latest post</SectionHeading>
    {children.map(article => <ArticleCard article={article} showAuthor className='min-h-1/2s' padding=''/>)}
  </div>
}

export function Articles({ children }) {
  return <>
    {children.map(article => <ArticleCard article={article} className='mb-5 md:mb-0 bg-gray-200 h-card' key={article.key} />)}
  </>
}
